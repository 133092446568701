@import "../../assets/colors";

.root {
  margin: 5px 32px;
  display: flex;
  align-items: center;
}

.selects {
  margin: 0 8px !important;
  font-family: 'Nunito Sans', sans-serif !important;
  font-size: 16px !important;
  line-height: 26px !important;
  height: 42px !important;
  width: 175px !important;
  box-sizing: border-box !important;
  >div {
    padding: 0 !important;
  }
  > fieldset {
    > legend {
      visibility: visible !important;
      > span {
        margin-top: -10px;
        color: $text-secondary !important;
      }

    }
  }
}

.input {
  font-family: 'Nunito Sans', sans-serif !important;
  color: $text-primary !important;
  font-weight: 400 !important;
  line-height: 26px !important;
  padding: 10px 8px 6px 8px !important;
}

.button {
  padding: 11px 20px !important;
  font-family: 'Open Sans', sans-serif !important;
  color: $text-primary !important;
  font-weight: 600 !important;
}
