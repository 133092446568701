@import "../../assets/colors";
@import "../../assets/mixins";

.root {
  min-width: 238px;
  width: 238px;
  max-width: 238px;
  height: 100vh;
  color: $white;
}

.logo {
  width: 122px;
  height: 51px;
  background-image: url("../../assets/images/logo.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin: 51px 0 85px 30px;
  box-sizing: border-box;
}

.listTitle {
  text-transform: uppercase;
  @include table-column-codes;
  @include bold-text;
  opacity: 0.8;
  margin:0  0 7px 27px;
}

.link {
  margin: 1px 11px;
  box-sizing: border-box;
  padding: 13px 12px 11px 16px;
  border-radius: 4px;
  > a {
    @include regular-text;
    @include bold-text;
    color: $white;
    text-decoration: none;
  }
  &.active {
    background-color: #1B4695;
  }
  &:hover {
    background-color: #1B4695;
  }
}

.flex {
  display: flex;
  align-items: center;
  > svg {
    margin-left: 7px;
    @include regular-text;
    @include bold-text;
  }
}
