.wrapper {
  max-width: 80%;
  width: 80%;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 14px;

  .from {
    .name {
      font-size: 13px;
      line-height: 18px;
      font-weight: 600;
      color: #1B1B1B;
      margin-bottom: 2px;
    }

    .fromTo {
      font-size: 12px;
      line-height: 16px;
      font-weight: 400;
      color: #797979;
    }
  }
}

.logo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.body {
  padding: 15px 27px;
  word-break: break-word;
  line-height: 20px;
  font-size: 13px;
  border: 0.5px solid white;

  .titleBody {
    margin-bottom: 29px;
    font-weight: 600;
  }

  .messageBody {
    font-weight: 400;
  }
}

.footer {
  margin-top: 13px;
  display: flex;
  justify-content: flex-end;

  > div {
    color: #797979;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
  }
}

.wrapButton {
  margin-top: 9px;

  .button {
    font-family: 'Open Sans', sans-serif !important;
    text-transform: capitalize !important;
    padding: 6px 10px !important;
    border: 1px solid #D1D1D1 !important;
    font-size: 11px !important;
    font-weight: 400 !important;
    line-height: 15px !important;
    color: #797979 !important;
  }
}
