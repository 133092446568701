@import "../../assets/colors";
@import "../../assets/mixins";

.thead {
  text-transform: uppercase;
  @include table-head;
  color: $text-secondary !important;
  background-color: $white !important;
  padding: 18px 10px !important;
}

.tbody {
  @include table-head;
  color: $text-secondary !important;
  background-color: $white !important;
  padding: 18px 10px !important;
}

.date {
  @include breadcrumbs;
  color: $light-black;
}

.codes {
  @include table-column-codes;
  font-weight: 400;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.title {
  @include regular-text;
  color: $black;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.number {
  padding-left: 30px !important;
  @include breadcrumbs;
  color: $text-secondary !important;
}

.theadNumber {
  padding-left: 30px !important;
}

.country {
  @include breadcrumbs;
  color: $light-black;
}

.status {
  @include regular-text;
  @include bold-text;
  color: $white;
  padding: 4px 10px;
  display: inline-flex;
  border-radius: 4px;
}

.active {
  background-color: $orange;
}

.closed {
  background-color: $green;
}

.default {
  background-color: $text-secondary;
}

.empty {
  color: $text-secondary;
  padding: 20px 15px;
  @include bold-text;
  @include title-header;
}

.table {
  border-radius: 4px !important;
  overflow: hidden;
}

.row {
  cursor: pointer;
  &:hover {
    >td {
      background-color: #FAFAFA !important;
    }

  }
}
