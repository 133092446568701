@import "../../assets/colors";
@import "../../assets/mixins";

.root {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  color: $white;
}

.title {
  margin-top: 49px;
  @include regular-text;
  > span {
    @include title-header;
  }
  a {
    color: white;
  }
}

.wrapSelect {
  margin-top: 33px;
  display: flex;
  align-items: center;
  margin-right: 38px;
}

.select {
  margin-right: 16px;
  > svg {
    color: #fff;
    padding-left: 14px;
    margin-right: 14px;
  }
}

.userIcon {
  color: $white;
  > svg {
    font-size: 2rem;
  }
}
