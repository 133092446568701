@mixin table-head {
  font-size: 11px !important;
  font-weight: 600 !important;
  line-height: 15px !important;
}

@mixin table-column-codes {
  font-size: 12px;
  line-height: 16px;
}

@mixin regular-text {
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
}

@mixin bold-text {
  font-weight: 600;
}

@mixin title-page {
  font-size: 32px;
  line-height: 44px;
  @include bold-text;
}

@mixin text-button-primary {
  font-size: 16px;
  line-height: 22px;
  @include bold-text;
}

@mixin title-header {
  font-size: 22px;
  font-weight: 700;
  line-height: 30px;
}

@mixin breadcrumbs {
  @include regular-text;
  font-weight: 400 !important;
}
