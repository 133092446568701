@import "./assets/colors";
@import "./assets/mixins";

.root {
  background: linear-gradient(180deg, #062A6C 0%, #2A5B94 100%);
  min-height: 100vh;
  min-width: 100%;
  display: flex;
  font-family: 'Open Sans', sans-serif;
  overflow: auto;
}

.content {
  width: 100%;
  margin-left: 27px;
  box-sizing: border-box;
}

.main {
  color: $black;
  margin: 43px 76px 40px 0;
  box-sizing: border-box;
  height: calc(100% - 98px - 83px);
  position: relative;
  background-color: $white;
  box-shadow: 0px 0px 20px #022159;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 1100px){
  .main {
    margin: 43px 5px 40px !important;
  }
  .content {
    margin-left: 10px !important;
  }
}

.title {
  @include title-page;
  padding: 39px 40px;
  box-sizing: border-box;
}

.rootWrap {
  position: relative;
  height: 100%;
  width: 100%;
  margin-top: 20px;
}

.loader {
  position: absolute;
  top:0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.error {
  @include bold-text;
  position: absolute;
  top:0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}
