@import "../../assets/colors";
@import "../../assets/mixins";

.wrapperLoader {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;

}

.main {
  color: $black;
  margin: 43px 76px 40px 0;
  box-sizing: border-box;
  height: calc(100% - 98px - 83px);
  position: relative;
  background-color: $white;
  box-shadow: 0px 0px 20px #022159;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 1100px) {
  .main {
    margin: 43px 5px 40px !important;
  }
  .content {
    margin-left: 10px !important;
  }
}

.header {
  display: flex;
  align-items: center;
  padding: 34px 30px 28px 30px;
  border-bottom: 1px solid #E5E5E5;
  .iconBack {
    cursor: pointer;
  }
  >div {
    @include title-page;
    margin-left: 20px;
  }
}

.status {
  @include regular-text;
  @include bold-text;
  color: $white;
  padding: 4px 10px;
  display: inline-flex;
  border-radius: 4px;
  margin-left: 34px;
}

.active {
  background-color: $orange;
}

.closed {
  background-color: $green;
}

.default {
  background-color: $text-secondary;
}

.body {
  display: grid;
  position: absolute;
  grid-template-columns: 39% 61%;
  top: 107px;
  bottom: 0;
  left: 0;
  right: 0;
  .info {
    background-color: $white;
    padding: 23px 75px 75px 67px;
  }
  .messages {
    background-color: #FAFAFA;
    padding: 23px 38px 20px 42px;
  }
  .subtitle {
    font-size: 18px;
    line-height: 25px;
    font-weight: 700;
    margin-bottom: 4px;
  }
  .infoRow {
    margin-top: 30px;
    .infoRowTitle {
      @include table-column-codes;
      @include bold-text;
      text-transform: uppercase;
    }
    .infoRowContent {
      word-break: break-word;
      font-weight: 400;
      font-size: 13px;
      line-height: 18px;
      margin-top: 8px;
    }
  }
}

.emptyMessage {
  height: 100%;
  .emptyContent {
    color: $text-primary;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    >div {
      margin-top: 20px;
      @include regular-text;
    }
  }
}

.rowMessage {
  display: flex;
}
